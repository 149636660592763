import React from 'react';
import Layout from './Layout';
import PublicProductInfo from '../components/product component/PublicProductInfo';

const DetailProduct = () => {
  return (
    <Layout>
      <PublicProductInfo />
    </Layout>
  );
};

export default DetailProduct;
